.CardCommunityExperiment_mainContainer__11jdm4c0 {
  display: flex;
  flex-direction: column;
}
.CardCommunityExperiment_mainContentContainer__11jdm4c1 {
  display: flex;
  flex-direction: column;
  margin-right: 0.625rem;
}
.CardCommunityExperiment_contentContainer__11jdm4c2 {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.CardCommunityExperiment_subContainer__11jdm4c3 {
  display: flex;
  flex-direction: column;
  margin-top: 0.875rem;
}
.CardCommunityExperiment_commentContainer__11jdm4c4 {
  font-size: var(--seed-semantic-typography-body-m2-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m2-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-700);
  padding: 0.75rem;
  border-radius: 0.375rem;
  background-color: var(--seed-semantic-color-paper-contents);
}
.CardCommunityExperiment_badgeCategory__11jdm4c5 {
  font-size: var(--seed-semantic-typography-label5-regular-font-size);
  font-weight: var(--seed-semantic-typography-label5-regular-font-weight);
  line-height: var(--seed-semantic-typography-label5-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label5-regular-letter-spacing);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem 0.375rem;
  border-radius: 0.125rem;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: var(--seed-scale-color-gray-100);
  color: var(--seed-scale-color-gray-700);
  margin-right: auto;
}
.CardCommunityExperiment_titleContainer__11jdm4c6 {
  font-size: var(--seed-semantic-typography-body-m1-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m1-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m1-regular-letter-spacing);
  color: var(--seed-scale-color-gray-900);
}
.CardCommunityExperiment_descriptionContainer__11jdm4c7 {
  margin-top: 0.25rem;
  font-size: var(--seed-semantic-typography-body-m2-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m2-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-700);
}
.CardCommunityExperiment_imageContainer__11jdm4c8 {
  flex: 0 0 auto;
  width: 4.5rem;
  height: 4.5rem;
  background-color: var(--seed-semantic-color-paper-background);
  border-radius: 0.375rem;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
}
.CardCommunityExperiment_imageContainer__11jdm4c8 .CardCommunityExperiment_image__11jdm4c9 {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: cover;
  border-radius: 0.375rem;
}
.CardCommunityExperiment_image__11jdm4c9[src=""] {
  display: none;
}
.CardCommunityExperiment_imageCount__11jdm4ca {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--seed-semantic-color-overlay-dim);
  padding: 0 0.375rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 0.375rem;
  font-size: var(--seed-semantic-typography-label5-regular-font-size);
  font-weight: var(--seed-semantic-typography-label5-regular-font-weight);
  line-height: var(--seed-semantic-typography-label5-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label5-regular-letter-spacing);
  color: var(--seed-static-color-static-white);
}
.CardCommunityExperiment_additionalInfoContainer__11jdm4cb {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  align-items: center;
}
.CardCommunityExperiment_userReactionsContainer__11jdm4cd {
  display: flex;
  flex-direction: row;
}
.CardCommunityExperiment_userReactionContainer__11jdm4ce {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.25rem;
}
.CardCommunityExperiment_userReactionContainer__11jdm4ce:last-child {
  margin-right: 0;
}
.CardCommunityExperiment_iconContainer__11jdm4cf {
  display: flex;
  color: var(--seed-scale-color-gray-400);
}
.CardCommunityExperiment_userReactionThumbUpCount__11jdm4cg {
  font-size: var(--seed-semantic-typography-caption2-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption2-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-600);
  margin-left: 0.125rem;
}
.CardCommunityExperiment_userReactionReplyCount__11jdm4ch {
  font-size: var(--seed-semantic-typography-caption2-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption2-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-600);
  margin-left: 0.125rem;
}
.CardCommunityExperiment_lineClampWithEllipsis__11jdm4ci {
  display: -webkit-box;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.CardCommunityExperiment_lineClampWithEllipsis_lineClamp_1__11jdm4cj {
  -webkit-line-clamp: 1;
}
.CardCommunityExperiment_lineClampWithEllipsis_lineClamp_2__11jdm4ck {
  -webkit-line-clamp: 2;
}