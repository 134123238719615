.HotKeyword_Container__5ayul60 {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.875rem;
  border-radius: 10rem;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 300ms;
  text-decoration: none;
  outline: none;
  margin-right: 0.5rem;
  cursor: pointer;
  background-color: var(--seed-scale-color-gray-100);
}
.HotKeyword_Container__5ayul60:last-child {
  margin-right: 0;
}
.HotKeyword_Container__5ayul60:active {
  background-color: var(--seed-scale-color-gray-200);
  transition: background-color 0s;
}
.HotKeyword_Keyword__5ayul61 {
  font-size: var(--seed-semantic-typography-subtitle2-regular-font-size);
  font-weight: var(--seed-semantic-typography-subtitle2-regular-font-weight);
  line-height: var(--seed-semantic-typography-subtitle2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-subtitle2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-900);
}