.CardShortform_videoThumbnail__1cpgvqh0 {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  padding-bottom: 150%;
  border-radius: 6px;
  overflow: hidden;
}
.CardShortform_videoThumbnail__1cpgvqh0::before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
}
.CardShortform_image__1cpgvqh1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.CardShortform_localProfileIcon__1cpgvqh2 {
  flex-shrink: 0;
}
.CardShortform_localProfileName__1cpgvqh3 {
  font-size: var(--seed-semantic-typography-caption1-bold-font-size);
  font-weight: var(--seed-semantic-typography-caption1-bold-font-weight);
  line-height: var(--seed-semantic-typography-caption1-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-bold-letter-spacing);
  color: var(--seed-static-color-static-white);
  margin: 0 0 0 0.1875rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.CardShortform_localProfileNameContainer__1cpgvqh4 {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
  left: 10px;
  display: flex;
  align-items: center;
}
.CardShortform_videoDescription__1cpgvqh5 {
  font-size: var(--seed-semantic-typography-body-l2-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-l2-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-l2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-l2-regular-letter-spacing);
  color: var(--seed-scale-color-gray-900);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.5rem 0 0;
  word-break: break-all;
}
.CardShortform_tagGroupContainer__1cpgvqh6 {
  margin: 0.1875rem 0 0 0;
}