._1dlf4347 {
  background-color: var(--stackflow-plugin-basic-ui-dim-background-color);
}
._1dlf4348 {
  background-color: var(--stackflow-plugin-basic-ui-background-color);
  background-image: var(--stackflow-plugin-basic-ui-background-image);
}
._1dlf4349 {
  transition: transform var(--_1dlf4340), opacity var(--_1dlf4340);
}
._1dlf434d {
  transform: translate3d(100%, 0, 0);
}
._1dlf434j {
  opacity: 0;
  z-index: var(--_1dlf4341);
}
._1q6dj2j0 ._1dlf434j {
  height: 10rem;
  background: linear-gradient(var(--stackflow-plugin-basic-ui-dim-background-color), rgba(0, 0, 0, 0));
}

      ._1dlf434a ._1dlf434j,
      ._1dlf434b ._1dlf434j
     {
  opacity: 1;
}

      ._1dlf434c ._1dlf434j,
      ._1dlf434d ._1dlf434j
     {
  opacity: 0;
}
._1dlf434k {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: var(--_1dlf4342);
}
._1dlf434k::-webkit-scrollbar {
  display: none;
}
._1q6dj2j1 ._1dlf434k {
  transform: translate3d(100%, 0, 0);
}

        ._1q6dj2j1 ._1dlf434a ._1dlf434k,
        ._1q6dj2j1 ._1dlf434b ._1dlf434k
       {
  transform: translate3d(0, 0, 0);
}
._1q6dj2j0 ._1dlf434k {
  opacity: 0;
  transform: translate3d(0, 10rem, 0);
}

        ._1q6dj2j0 ._1dlf434a ._1dlf434k,
        ._1q6dj2j0 ._1dlf434b ._1dlf434k
       {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
._1dlf434l {
  --_1dlf4345: var(--stackflow-plugin-basic-ui-app-bar-height);
  transition: transform var(--_1dlf4340), opacity var(--_1dlf4340), margin-top var(--stackflow-plugin-basic-ui-app-bar-height-transition-duration);
  margin-top: var(--_1dlf4345);
  height: calc(100% - var(--_1dlf4345));
}
._1q6dj2j1 ._1dlf434m {
  transform: translate3d(0, 100%, 0);
}
._1q6dj2j0 ._1dlf434n {
  transform: translate3d(50%, 0, 0);
}
._1dlf434o {
  width: 1.25rem;
  z-index: var(--_1dlf4343);
}
._1dlf434p {
  top: var(--stackflow-plugin-basic-ui-app-bar-height);
  height: calc(100% - var(--stackflow-plugin-basic-ui-app-bar-height));
}
@supports (padding: max(0px)) and (padding: constant(safe-area-inset-top)) {
  ._1dlf434l {
    --_1dlf4345: calc(var(--stackflow-plugin-basic-ui-app-bar-height) + max(var(--stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top), constant(safe-area-inset-top)));
  }
}
@supports (padding: max(0px)) and (padding: env(safe-area-inset-top)) {
  ._1dlf434l {
    --_1dlf4345: calc(var(--stackflow-plugin-basic-ui-app-bar-height) + max(var(--stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top), env(safe-area-inset-top)));
  }
}