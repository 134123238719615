._1ht38h03 {
  transition: var(--_1ht38h00);
}
._1ht38h07 {
  transform: translate3d(100%, 0, 0);
}
._1ht38h0d {
  background-color: var(--stackflow-plugin-basic-ui-dim-background-color);
  z-index: var(--_1ht38h01);
  opacity: 0;
}
._1ht38h04 ._1ht38h0d, ._1ht38h05 ._1ht38h0d {
  opacity: 1;
}
._1ht38h06 ._1ht38h0d, ._1ht38h07 ._1ht38h0d {
  opacity: 0;
}
._1ht38h0e {
  background-color: var(--stackflow-plugin-basic-ui-background-color);
  background-image: var(--stackflow-plugin-basic-ui-background-image);
  width: 100%;
  border-radius: var(--stackflow-plugin-basic-ui-bottom-sheet-border-radius) var(--stackflow-plugin-basic-ui-bottom-sheet-border-radius) 0 0;
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
._1ht38h04 ._1ht38h0e, ._1ht38h05 ._1ht38h0e {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
._1ht38h06 ._1ht38h0e, ._1ht38h07 ._1ht38h0e {
  transform: translate3d(0, 100%, 0) !important;
  opacity: 0 !important;
}